import { FloatingPortal } from '@floating-ui/react';
import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons/faArrowRightLong';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import { ENTERING, EXITED, EXITING } from 'react-transition-group/Transition';

import { useFloatingForAutocomplete } from './useFloating';

/**
 * @param TGroup: groups multiple results
 * @param TResult: single result value
 */
interface Props<TGroup extends { id: string }, TResult extends { group: TGroup }> {
  id: string;
  search: (value: string) => Promise<TResult[]>;
  onSelectValue: (value: TResult | null) => void;
  groupLabel: (group: TGroup) => string;
  groupSubLabel: (group: TGroup) => string | null;
  label: (result: TResult) => string;
}

export function AutocompleteInput<
  TGroup extends { id: string },
  TResult extends { group: TGroup },
>({ id, search, onSelectValue, groupLabel, groupSubLabel, label }: Props<TGroup, TResult>) {
  const {
    isOpen,
    inputProps,
    searchResults,
    refs,
    listRef,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
    activeIndex,
  } = useFloatingForAutocomplete({
    onSearch: async (value: string) => {
      const results = await search(value);

      // Sort by group, so that the keyboard list navigation is ordered properly
      results.sort((a, b) => a.group.id.localeCompare(b.group.id));

      return results;
    },
    onSelectValue,
  });

  // Ensure this is sorted in the same way
  const groupedResults = groupBySorted(searchResults, (result) => {
    return result.group.id;
  });

  return (
    <>
      <div
        ref={refs.setReference}
        className={clsx(
          'w-full rounded-lg border-3 border-white bg-white p-3 transition-colors duration-300 ease-epease hover:border-epminfrapurple-100 hover:bg-epminfrapurple-100 focus:border-epminfrapurple-100 focus:bg-white',
        )}
        {...getReferenceProps()}
      >
        <input
          className="w-full bg-transparent outline-none"
          id={id}
          autoComplete="off"
          {...inputProps}
        />
      </div>
      <CSSTransition
        in={isOpen}
        addEndListener={(done) => {
          refs.floating.current?.addEventListener('transitionend', done, false);
        }}
        unmountOnExit
        nodeRef={refs.floating}
        classNames={{
          appear: 'translate-y-10',
        }}
      >
        {(state) => (
          <FloatingPortal>
            <div
              className={clsx(
                state === ENTERING || state === EXITING || state === EXITED
                  ? 'translate-y-10 opacity-0'
                  : 'translate-y-0 opacity-100',
                'ep-card-shadow flex flex-col gap-3 overflow-y-scroll rounded-lg bg-white p-3 transition-all duration-200 ease-epease',
              )}
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              {groupedResults.map((results) => (
                <div
                  key={results[0].group.id}
                  className={clsx(
                    activeIndex !== null &&
                      activeIndex >= results[0].idx &&
                      activeIndex <= results[results.length - 1].idx &&
                      'bg-epminfrapurple-100',
                    'cursor-pointer rounded-[0.25rem] p-2 hover:bg-epminfrapurple-100',
                  )}
                >
                  <SearchResult
                    group={results[0].group}
                    results={results}
                    activeIndex={activeIndex}
                    onSelectValue={onSelectValue}
                    groupLabel={groupLabel}
                    groupSubLabel={groupSubLabel}
                    label={label}
                    refCb={(element, idx) => {
                      // eslint-disable-next-line security/detect-object-injection
                      listRef.current[idx] = element;
                    }}
                  />
                </div>
              ))}
            </div>
          </FloatingPortal>
        )}
      </CSSTransition>
    </>
  );
}

function SearchResult<TGroup extends { id: string }, TResult extends { group: TGroup }>({
  results,
  group,
  activeIndex,
  onSelectValue,
  groupLabel,
  groupSubLabel,
  label,
  refCb,
}: {
  results: (TResult & { idx: number })[];
  group: TGroup;
  activeIndex: number | null;
  onSelectValue: (result: TResult) => void;
  groupLabel: (group: TGroup) => string;
  groupSubLabel: (group: TGroup) => string | null;
  label: (result: TResult) => string;
  refCb: (element: HTMLDivElement | null, idx: number) => void;
}) {
  if (results.length === 1) {
    const result = results[0];
    return (
      <div
        className="flex items-center justify-between"
        onClick={() => onSelectValue(result)}
        ref={(el) => refCb(el, result.idx)}
      >
        <div>
          <div className="font-medium">{groupLabel(group)}</div>
          <div className="text-sm text-epmspacedust-400">
            {groupSubLabel(group) ?? label(result)}
          </div>
        </div>
        <FontAwesomeIcon icon={faArrowRightLong} className="ml-2 size-4 text-epspacedust" />
      </div>
    );
  } else {
    return (
      <div className="flex items-center justify-between">
        <div>
          <div className="font-medium">{groupLabel(group)}</div>
          <div className="text-sm text-epmspacedust-400">{groupSubLabel(group)}</div>
          <div className="mt-2 flex flex-col gap-2">
            {results.map((result) => (
              <div
                className={clsx(
                  activeIndex === result.idx ? 'bg-epminfrapurple-300' : 'bg-epminfrapurple-200',
                  'flex items-center justify-between rounded-lg p-2 hover:bg-epminfrapurple-300',
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  return onSelectValue(result);
                }}
                key={JSON.stringify(result)}
                ref={(el) => refCb(el, result.idx)}
              >
                {label(result)}

                <FontAwesomeIcon icon={faArrowRightLong} className="ml-2 size-4 text-epspacedust" />
              </div>
            ))}
          </div>
        </div>
        <FontAwesomeIcon
          icon={faArrowRightLong}
          className="ml-2 size-4 text-epspacedust transition-transform duration-300 ease-epease"
        />
      </div>
    );
  }
}

// Groups by given key. Accepts a sorted list and ensures that the result is sorted in the same way
function groupBySorted<T>(
  array: readonly T[],
  key: (item: T) => string,
): (T & { idx: number })[][] {
  const ret: (T & { idx: number })[][] = [];

  const firstElement = array[0];
  if (!firstElement) {
    return ret;
  }
  ret.push([{ ...firstElement, idx: 0 }]);
  let currentIdx = 0;

  for (let i = 1; i < array.length; ++i) {
    // eslint-disable-next-line security/detect-object-injection
    const el = array[i];
    const currentKey = key(ret[ret.length - 1][0]);
    const nextKey = key(el);
    if (currentKey === nextKey) {
      ret[ret.length - 1].push({ ...el, idx: ++currentIdx });
    } else {
      ret.push([{ ...el, idx: ++currentIdx }]);
    }
  }

  return ret;
}
